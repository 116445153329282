// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService, User } from '../../../../core/auth';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	type: string = 'email';
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>;

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		public snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if (params['type']) {
				this.type = params['type'];
			}
		});
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', []], // Initialize without validators
			mobile: ['', []] // Initialize without validators
		});

		if (this.type === 'email') {
			this.forgotPasswordForm.get('email').setValidators([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]);
			this.forgotPasswordForm.get('mobile').clearValidators();
		} else {
			this.forgotPasswordForm.get('mobile').setValidators([
				Validators.required,
				Validators.pattern('^\\d{10}$')
			]);
			this.forgotPasswordForm.get('email').clearValidators();
		}

		// Ensure updates are reflected
		this.forgotPasswordForm.get('email').updateValueAndValidity();
		this.forgotPasswordForm.get('mobile').updateValueAndValidity();
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const user: User = new User();
		if (this.type == 'email') {
			user.email = controls.email.value.trim();
			user.firstName = '';

			this.authService.requestPassword(user.email).pipe(
				tap(response => {
					if (response.statusCode == 200) {
						this.openDialog(user, 3, 0);
						this.authNoticeService.setNotice(null);
						/*	this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
							this.router.navigateByUrl('/auth/login'); */
					} else {
						this.authNoticeService
							.setNotice(this.translate
								.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.EMAIL') }), 'danger');
					}
				}, error=>{
					this.snackBar.open('Error occurred while resetting password. Please try again.', '', {
						duration: 3000,
						panelClass: ['red-snackbar']
					  });
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			).subscribe();
		} else {
			user.phone = controls.mobile.value.trim();
			user.firstName = '';

			this.authService.requestOtp(user.phone).pipe(
				tap(response => {
					if (response.statusCode == 200) {
						user.email = response.responseData.userEmail;
						user.id = response.responseData.userId;
						this.openDialog(user, 5, 0);
						this.authNoticeService.setNotice(null);
					} else {
						this.authNoticeService
							.setNotice(this.translate
								.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.MOBILE') }), 'danger');
					}
				}, error=>{
					this.snackBar.open('Error occurred while resetting password. Please try again.', '', {
						duration: 3000,
						panelClass: ['red-snackbar']
					  });
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			).subscribe();
		}
		this.authService.setUser(user);
	}
	
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
	/**
	 * 
	 * @param user 
	 * @param mcode 
	 * @param resendFlag 
	 */
	openDialog(user: any, mcode: number, resendFlag: number): void {
		let dialogRef = this.dialog.open(AuthDialogComponent, {
			width: '650px',
			data: { name: user.firstName, email: user.email, code: mcode, resendFlag: resendFlag, phone: user.phone }
		});

		dialogRef.afterClosed().subscribe(() => {
			if (this.type === 'email') {
				this.router.navigateByUrl('/auth/login');
			} else {
				this.router.navigateByUrl('/auth/authenticate-otp');
			}
		});
	}
}
